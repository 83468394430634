
@tailwind base;
@tailwind components;
@tailwind utilities;


@property --border-angle {
  inherits: false;
  initial-value: 0deg;
  syntax: '<angle>';
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", 
    monospace;
}

/* TODO: Buy license for tinyMCE */
.tox-notifications-container {
  display: none;
}